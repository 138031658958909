import React, { SetStateAction, useState, useRef } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { useSwipeable } from "react-swipeable"
import { color, width, mq, breakPoint, header } from "../utils/style-components"
import { ReactComponent as Logo } from "../img/common/logo.svg"
import { ReactComponent as Instagram } from "../img/common/icon_instagram.svg"
import { ReactComponent as NoteIcon } from "../img/common/icon_note.svg"

type GnavType = {
  spnavState?: boolean
  setSpnavState?: React.Dispatch<SetStateAction<boolean>>
}

const Gnav: React.FC<GnavType> = ({ spnavState, setSpnavState }) => {
  const translateX = spnavState ? `0%` : `100%`
  const wrapRef = useRef<HTMLElement>(
    null
  ) as React.MutableRefObject<HTMLElement>
  const isSP = useMediaQuery({ query: `(max-width: ${breakPoint - 1}px` })
  const resetSwipeStyle = () => {
    wrapRef.current.style.transition = ``
    wrapRef.current.style.transform = ``
  }
  const swipeHandlers = useSwipeable({
    onSwipeStart: () => {
      if (isSP && wrapRef.current) {
        wrapRef.current.style.transition = `none`
      }
    },
    onSwiping: event => {
      if (isSP && wrapRef.current) {
        if (event.deltaX > 30) {
          resetSwipeStyle()
          if (setSpnavState) setSpnavState(false)
        } else if (event.deltaX > 0) {
          wrapRef.current.style.transform = `translateX(${
            event.deltaX > 0 ? event.deltaX : 0
          }px)`
        }
      }
    },
    onSwiped: () => {
      if (isSP) {
        resetSwipeStyle()
      }
    },
  })
  const refPassthrough = (el: HTMLElement) => {
    swipeHandlers.ref(el)
    wrapRef.current = el
  }

  return (
    <nav
      {...swipeHandlers}
      ref={refPassthrough}
      css={css`
        ${mq(
          `sp`,
          css`
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            max-height: 100%;
            overflow-y: auto;
            background: ${color.white};
            z-index: 80;
            transition: transform 0.3s;
            transform: translateX(${translateX});
          `
        )}
      `}
    >
      <ul
        css={css`
          list-style: none;
          ${mq(
            `pc`,
            css`
              display: flex;
              margin: 0;
              padding: 9px 0;
            `
          )}
          ${mq(
            `sp`,
            css`
              padding: 65px 0 0 26px;
            `
          )}
        `}
      >
        <GnavItem>
          <Link to="/why">WHY</Link>
        </GnavItem>
        <GnavItem>
          <Link to="/values">VALUES</Link>
        </GnavItem>
        <GnavItem>
          <Link to="/gallery">GALLERY</Link>
        </GnavItem>
        <GnavItem>
          <Link to="/contact">CONTACT</Link>
        </GnavItem>
        <GnavItem>
          <Link to="/recruit">RECRUIT</Link>
        </GnavItem>
        <GnavItem>
          <Link to="/#access" state={{ skipMovie: true }}>
            ACCESS
          </Link>
        </GnavItem>
        <GnavIcons>
          <a
            aria-label="Instagram @tennei_hachijo"
            href="https://www.instagram.com/tennei_hachijo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram
              css={css`
                width: 27px;
                ${mq(
                  `sp`,
                  css`
                    g {
                      fill: ${color.blue};
                    }
                  `
                )}
              `}
            />
          </a>
          <a
            aria-label="公式note"
            href="https://note.com/tennei_hachijo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NoteIcon
              css={css`
                height: 27px;
                ${mq(
                  `sp`,
                  css`
                    path {
                      fill: ${color.blue};
                    }
                  `
                )}
              `}
            />
          </a>
        </GnavIcons>
      </ul>
      <MediaQuery maxWidth={breakPoint - 1}>
        <Link
          to="https://group-fm.com/policy/"
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            display: block;
            padding: 5px 0;
            text-decoration: none;
            font-size: 1.4rem;
            color: ${color.white};
            text-align: center;
            background: ${color.blue};
          `}
        >
          privacy policy
        </Link>
      </MediaQuery>
    </nav>
  )
}

type HamburgerType = {
  spnavState: boolean
  setSpnavState: React.Dispatch<SetStateAction<boolean>>
}

const Hamburger: React.FC<
  HamburgerType & React.HTMLAttributes<HTMLButtonElement>
> = props => {
  const { setSpnavState, spnavState, ...rest } = props
  return (
    <button
      aria-label="メニューを開く"
      onClick={() => {
        setSpnavState(!spnavState)
      }}
      type="button"
      css={css`
        position: relative;
        display: block;
        background: white;
        width: 59px;
        height: 59px;
        border-radius: 50%;
        & > span {
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
          width: 26px;
          height: 5px;
          background: ${color.blue};
          transform: translateY(-50%);
          transition: transform 0.3s, width 0.3s, top 0.3s;
          &:nth-of-type(1) {
            top: 35%;
          }
          &:nth-of-type(2) {
            top: 50%;
          }
          &:nth-of-type(3) {
            top: 65%;
          }
        }
        &.active span {
          &:nth-of-type(1) {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
          &:nth-of-type(2) {
            width: 0px;
          }
          &:nth-of-type(3) {
            top: 50%;
            transform: translateY(-50%) rotate(135deg);
          }
        }
      `}
      {...rest}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

const Header: React.FC = () => {
  const [spnavState, setSpnavState] = useState(false)
  let hamburgerClass = spnavState ? `active` : ``
  return (
    <>
      <header
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 90;
          ${mq(
            `pc`,
            css`
              background-color: ${color.blue};
              height: ${header.height};
            `
          )}
        `}
      >
        <div
          css={css`
            ${mq(
              `pc`,
              css`
                display: flex;
                justify-content: flex-end;
                max-width: ${width.contentPC};
                margin: 0 auto;
                position: relative;
              `
            )}
          `}
        >
          <h1
            css={css`
              ${mq(
                `pc`,
                css`
                  position: absolute;
                  top: 0;
                  left: 0;
                `
              )}
              ${mq(
                `sp`,
                css`
                  height: 0;
                `
              )}
            `}
          >
            <Link aria-label="Top" to="/" state={{ skipMovie: true }}>
              <Logo
                css={css`
                  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.1));
                  ${mq(
                    `pc`,
                    css`
                      max-width: 278px;
                      width: 21vw;
                      margin: 7px 0 0 10px;
                    `
                  )}
                  ${mq(
                    `sp`,
                    css`
                      margin: 1% 0 0 ${width.paddingSP};
                      min-width: 132px;
                      width: 36%;
                    `
                  )}
                `}
              />
            </Link>
          </h1>
          <MediaQuery maxWidth={breakPoint - 1}>
            <Hamburger
              className={hamburgerClass}
              setSpnavState={setSpnavState}
              spnavState={spnavState}
              css={css`
                position: absolute;
                top: 0;
                margin-top: 1%;
                right: ${width.paddingSP};
              `}
            />
          </MediaQuery>
          <MediaQuery minWidth={breakPoint}>
            <Gnav />
          </MediaQuery>
        </div>
      </header>
      <MediaQuery maxWidth={breakPoint - 1}>
        <Gnav setSpnavState={setSpnavState} spnavState={spnavState} />
      </MediaQuery>
    </>
  )
}

const GnavItem = styled.li`
  & > a {
    line-height: 1;
    text-decoration: none;
  }
  ${mq(
    `pc`,
    css`
      padding: 0 20px;
      &:nth-of-type(n + 2) {
        border-left: 1px solid ${color.white};
      }
      &:last-of-type {
        border: none;
      }
      & > a {
        font-size: 1.8rem;
        line-height: 1;
        color: ${color.white};
        font-weight: 700;
        text-decoration: none;
      }
    `
  )}
  ${mq(
    `sp`,
    css`
      & > a {
        display: block;
        width: 150px;
        padding: 15px 30px;
        font-size: 1.6rem;
        text-align: right;
        border-bottom: 1px dotted ${color.blue};
      }
      &:last-of-type > a {
        border: none;
      }
    `
  )}
`

const GnavIcons = styled.li`
  display: flex;
  align-items: center;
  & > a {
    line-height: 1;
    text-decoration: none;
    margin-left: 1.5em;
    &:first-of-type {
      margin-left: 0;
    }
  }
  ${mq(
    `pc`,
    css`
      padding: 0 20px;
      &:nth-of-type(n + 2) {
        border-left: 1px solid ${color.white};
      }
      &:last-of-type {
        border: none;
      }
      & > a {
        font-size: 1.8rem;
        line-height: 1;
        color: ${color.white};
        font-weight: 700;
        text-decoration: none;
      }
    `
  )}
  ${mq(
    `sp`,
    css`
      justify-content: flex-end;
      width: 150px;
      padding: 15px 30px;
      & > a {
        display: block;
        font-size: 1.6rem;
        text-align: right;
      }
    `
  )}
`

export default Header
